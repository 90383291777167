// import logo from './logo.svg';
// import './App.css';

import './App.scss';
// import Navbar from './components/Navbar';
// import Hero from './components/Hero';
// import AboutUs from './components/AboutUs';
// import Portfolio from './components/Portfolio';
// import Testimonials from './components/Testimonials';
// import Footer from './components/Footer';
// import Team from './components/Team';
// import Contact from './components/Contact';
import PageNotFound from './components/PageNotFound';


import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import PortfolioItemViewPage from './components/PortfolioItemViewPage';
import HomePage from './components/HomePage';
import ProjectPage from './components/ProjectPage';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={ <HomePage /> } />
        <Route path='/projects/:id' element={ <ProjectPage /> } />
        <Route path='*' element={ <PageNotFound /> } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
