import React from 'react';
import { Link } from 'react-router-dom';
import gitImage from '../assets/images/icons/github.svg';
import linkedinImage from '../assets/images/icons/linkedin.svg';
import TeamProgressBar from './TeamProgressBar';


function TeamSliderItem({member}) {
  const {name, position, img, git, linkedin, startingDate} = member;

  const image = require(`../assets/images/team/${img}`).default;
  return (
    <div className='team__slider__box'>
      <img src={ image } alt='img' className='team__slider__box__image w-100' />
      <div className='team__slider__box__text'>
        <p className='team__slider__box__text--position'>{position}</p>
        <h4 className='team__slider__box__text--name'>{name}</h4>
        <div className='team__slider__box__soc-med d-flex align-items-center'>
          <Link to={{pathname: linkedin}} target="_blank"><img src={linkedinImage} alt='linkedin' className='team__slider__box__soc-med--linkedin'/></Link>
          <Link to={{pathname: git}} target="_blank"><img src={gitImage} alt='github' /></Link>
        </div>
        <div className='eam__slider__box__progressbar mt-3'>
          <TeamProgressBar startingDate={startingDate} />
        </div>
      </div>
    </div>
  )
}

export default TeamSliderItem;
