import React from 'react';
import * as dayjs from 'dayjs';

function TeamProgressBar({ startingDate }) {

  const currentDate = dayjs();
  const startingJobDate = dayjs(startingDate);
  // Calculate how much months current employee is working
  const currentWorkingMonths = currentDate.diff(startingJobDate, 'month');

  const calculateLevel = (months) => {
    const fullLevel = months ? Math.sqrt(months)*5-4 : 0;
    return {
      level: Math.floor(fullLevel),
      currentLevelProgress: Math.round((fullLevel%1)*100)
    }
  }

  const employeeLevel = calculateLevel(currentWorkingMonths);

  return (
    <>
      <div className='progress'>
        <div className='progress-bar progress-bar-striped progress-bar-animated'
        aria-valuenow={employeeLevel.currentLevelProgress} aria-valuemin="0" aria-valuemax="100"
        style={ { width: `${employeeLevel.currentLevelProgress}%`, backgroundColor: '#FFD500' } }>
          {/* <span style={{width: '20px', height: '30px', borderRadius: '50%', backgroundColor: 'red'}}></span> */}
        </div>
      </div>
      <div className='mt-2'>
        <h4>Level { employeeLevel.level }</h4>
      </div>
    </>
  )
}

export default TeamProgressBar;
