import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import ProjectScreenshots from './ProjectScreenshots';
import hyundai from '../assets/images/hyundai.png';
import Spinner from './Spinner';


const ProjectViewItem = () => {

  let { id } = useParams();
  const [project, setProject] = useState();

  useEffect(() => {
    const getProjects = () => {
      fetch('../../data.json')
        .then(response => response.json())
        .then(data => {
          let selectedProject = data.projects.find((el) => el.id === Number(id));
          setProject(selectedProject)
        })
        .catch(err => console.log('error in catching project: ', err))
    };

    getProjects();
  }, [id]);

  if(project){
    const {project_folder} = project;
    try{
      var projectHero = require(`../assets/images/projects/${project_folder}/hero.png`).default
    } catch (e) {
      projectHero = hyundai;
    }
    // var projectHero = error ? hyundai : projectHeroImage;
  }

  return (
    <>
      {
        project ?
          <>
            <div className='container'>
              <div className='row'>
                <div className='project-info__title col-12 col-md-3'>
                  <h1 className='text-break'>{ project.name }</h1>
                </div>
                <div className='project-info__intro col-12 col-md-9'>
                  <p>{ project.about }</p>
                  <div className='project-info__intro__info d-flex justify-content-between'>
                    <p>Services: <span> { project.category }</span></p>
                    <p>Industry: <span> { project.industry }</span></p>
                    <p>Website url: <span> <a href={ project.url }>{ project.name }</a></span></p>
                    {/* <p>Deliverables: <span> { project.deadline }</span></p> */}
                  </div>
                </div>
                {/* THIS IS CODE FOR PROJECT HERO PAGE */}
                <div className='project-info__cover-img col-12 d-none d-md-block'>
                  <img src={ projectHero } alt='Project_cover_image' className='w-100 img-fluid' />
                </div>
              </div>
            </div>
            {/* Image need to be full width on mobile device */ }
            <img src={projectHero} className='project-info__cover-img d-md-none w-100' alt='project_cover_photo' />
            <div className='container'>
              {
                project.challenge && project.solution && 
                <div className='row mt-3 mt-md-5'>
                  <div className='project-info__challenge col-12 col-md-6'>
                    <h3>Challenge</h3>
                    <p>{project.challenge}</p>
                  </div>
                  <div className='project-info__solution col-12 col-md-6'>
                    <h3>Solution</h3>
                    <p>{project.solution}</p>
                  </div>
                </div>

              }
              {/* THIS IS PROJECTS SCREENSHOTS COMPONENT */}
              {/* <div className='row'>
                <ProjectScreenshots />
              </div> */}
              <div className='row project-info__result'>
                <h3 className='col-12'>The Result</h3>
                <p className='col-12'>{ project.result }</p>
              </div>
            </div>
          </>
          :
          <Spinner />
      }

    </>
  )
}

export default ProjectViewItem;
